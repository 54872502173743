/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "../store";
import { API_URL } from "@/const";
import { ROLE } from "@/const/role";

Vue.use(VueRouter);

/**
 * isPublic: true はログインしなくても遷移可能な画面
 * onlyAllowList: アクセス権限を指定する（無い場合は、全ユーザーアクセス可能と判定）
 */
let routes = [
  {
    path: "/",
    redirect: "/home",
    meta: {
      title: "ホームにリダイレクト",
    },
  },
  {
    path: "/home",
    component: () => import("../pages/home/index.vue"),
    meta: {
      title: "ホーム",
    },
  },
  {
    path: "/login",
    component: () => import("../pages/publics/login.vue"),
    meta: {
      title: "ログイン",
      isPublic: true,
    },
  },
  {
    path: "/auth/init/input",
    component: () => import("../pages/publics/passwordSetting.vue"),
    meta: {
      title: "本人確認_パスワード設定",
      isPublic: true,
    },
  },
  {
    path: "/reminder/input",
    component: () => import("../pages/publics/passwordReminder.vue"),
    meta: {
      title: "パスワードリマインダー",
      isPublic: true,
    },
  },
  {
    path: "/reminder/:id/input",
    component: () => import("../pages/publics/passwordReset.vue"),
    meta: {
      title: "パスワード再設定",
      isPublic: true,
    },
  },
  {
    path: "/reminder/sendcomp",
    component: () => import("../pages/publics/passwordReminderComp.vue"),
    meta: {
      title: "パスワードリマインダーメール送信完了",
      isPublic: true,
    },
  },
  {
    path: "/informations",
    component: () => import("../pages/information/informations.vue"),
    meta: {
      title: "お知らせ一覧",
    },
  },
  {
    path: "/information/:id",
    component: () => import("../pages/information/information.vue"),
    meta: {
      title: "お知らせ一覧",
    },
  },
  {
    path: "/profile",
    component: () => import("../pages/profile/index.vue"),
    meta: {
      title: "プロフィール詳細",
    },
  },
  {
    path: "/profile/edit",
    component: () => import("../pages/profile/edit/index.vue"),
    meta: {
      title: "プロフィール編集",
    },
  },
  {
    path: "/account",
    component: () => import("../pages/account/index.vue"),
    meta: {
      title: "アカウント一覧",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN, ROLE.COLLECTOR.ADMIN, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/schedule",
    component: () => import("../pages/schedule/index.vue"),
    meta: {
      title: "スケジュール一覧",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/manifest/csvupload/",
    component: () => import("../pages/manifest/csvupload/index.vue"),
    meta: {
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.DISPOSER.PROXY]
    },
  },
  {
    path: "/manifest/input",
    component: () => import("../pages/manifest/input/index.vue"),
    meta: {
      title: "マニフェスト予約",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/manifest/multipleinput",
    component: () => import("../pages/manifest/multipleinput/index.vue"),
    meta: {
      title: "マニフェスト複数予約",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/manifest/temporaryinput",
    component: () => import("../pages/manifest/temporaryinput/index.vue"),
    meta: {
      title: "マニフェスト空登録",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/manifest/proxy/input",
    component: () => import("../pages/manifest/proxy/input/index.vue"),
    meta: {
      title: "マニフェスト代理登録",
      onlyAllowList: [ROLE.COLLECTOR.PROXY],
    },
  },
  {
    path: "/manifest/proxy/multipleinput",
    component: () => import("../pages/manifest/proxy/multipleinput/index.vue"),
    meta: {
      title: "マニフェスト複数代理登録",
      onlyAllowList: [ROLE.COLLECTOR.PROXY],
    },
  },
  {
    path: "/manifests/all",
    component: () => import("../pages/manifest/all/index.vue"),
    meta: {
      title: "すべてのマニフェスト",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/manifest/csvformat/input",
    component: () => import("../pages/manifest/csvformat/input"),
    meta: {
      title: "マニフェスト一覧 CSVフォーマット作成",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/manifest/csvformat/edit/:id",
    component: () => import("../pages/manifest/csvformat/edit"),
    meta: {
      title: "マニフェスト一覧 CSVフォーマット編集",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/manifest/csvformat/duplicate/:csvformatid",
    component: () => import("../pages/manifest/csvformat/duplicate"),
    meta: {
      title: "マニフェスト一覧 CSVフォーマット複製",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/manifests/reserve",
    component: () => import("../pages/manifest/reserve/index.vue"),
    meta: {
      title: "交付前マニフェスト",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/manifests/progress",
    component: () => import("../pages/manifest/progress/index.vue"),
    meta: {
      title: "進行中マニフェスト",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/manifests/complete",
    component: () => import("../pages/manifest/complete/index.vue"),
    meta: {
      title: "完了マニフェスト",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/manifests/collectreport/before",
    component: () => import("../pages/manifest/collectreport/before/index.vue"),
    meta: {
      title: "終了報告前マニフェスト",
      onlyAllowList: [],
    },
  },
  {
    path: "/manifests/collectreport/after",
    component: () => import("../pages/manifest/collectreport/after/index.vue"),
    meta: {
      title: "終了報告済マニフェスト",
      onlyAllowList: [],
    },
  },
  {
    path: "/manifest/edit/:id",
    component: () => import("../pages/manifest/edit/index.vue"),
    meta: {
      title: "マニフェスト修正",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.PROXY],
    },
  },
  {
    path: "/manifest/collect/report/:id",
    component: () => import("../pages/manifest/collect/report/index.vue"),
    meta: {
      title: "運搬終了報告登録",
      onlyAllowList: [ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/manifest/collect/report/edit/:id",
    component: () => import("../pages/manifest/collect/report/edit/index.vue"),
    meta: {
      title: "運搬終了報告修正",
      onlyAllowList: [ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/manifest/:id",
    component: () => import("../pages/manifest/detail/index.vue"),
    meta: {
      title: "マニフェスト詳細",
      onlyAllowList: [ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/generator/contract/",
    component: () => import("../pages/generator/contract/index.vue"),
    meta: {
      title: "CBA契約業者一覧",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN],
    },
  },
  {
    path: "/generator/contract/step1/input",
    component: () =>
      import("../pages/generator/contract/step1/input/index.vue"),
    meta: {
      title: "CBA契約登録1 排出業者",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/generator/contract/step2/input",
    component: () =>
      import("../pages/generator/contract/step2/input/index.vue"),
    meta: {
      title: "CBA契約登録2 排出業者",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/generator/contract/step1/edit/:id",
    component: () => import("../pages/generator/contract/step1/edit/index.vue"),
    meta: {
      title: "CBA契約登録編集1 排出業者",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/generator/contract/step2/edit/:id",
    component: () => import("../pages/generator/contract/step2/edit/index.vue"),
    meta: {
      title: "CBA契約登録編集2 排出業者",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/generator/contract/:id",
    component: () => import("../pages/generator/contract/detail/index.vue"),
    meta: {
      title: "CBA契約業者詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.CONTRACTOR],
    },
  },
  {
    path: "/collector/step1/input",
    component: () => import("../pages/collector/step1/input/index.vue"),
    meta: {
      title: "運搬事業者登録1",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/collector/step2/input",
    component: () => import("../pages/collector/step2/input/index.vue"),
    meta: {
      title: "運搬事業者登録2",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/collector/step1/edit/:id",
    component: () => import("../pages/collector/step1/edit/index.vue"),
    meta: {
      title: "運搬事業者編集1",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/collector/step2/edit/:id",
    component: () => import("../pages/collector/step2/edit/index.vue"),
    meta: {
      title: "運搬事業者編集2",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/collector/contract",
    component: () => import("../pages/collector/contract/index.vue"),
    meta: {
      title: "CBA契約詳細",
      onlyAllowList: [ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/collector/cbacontract/input/:id",
    component: () => import("../pages/collector/cbacontract/input/index.vue"),
    meta: {
      title: "CBA契約登録 運搬業者",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/cbacontract/input/:id",
    component: () => import("../pages/disposer/cbacontract/input/index.vue"),
    meta: {
      title: "CBA加入登録（処分）",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/collector/cbacontract/edit/:id",
    component: () => import("../pages/collector/cbacontract/edit/index.vue"),
    meta: {
      title: "CBA契約編集 運搬業者",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/cbacontract/edit/:id",
    component: () => import("../pages/disposer/cbacontract/edit/index.vue"),
    meta: {
      title: "CBA加入情報編集（処分）",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/collector/:id",
    component: () => import("../pages/collector/detail/index.vue"),
    meta: {
      title: "運搬事業者詳細",
      onlyAllowList: [ROLE.CBA, ROLE.COLLECTOR.ADMIN],
    },
  },
  {
    path: "/operate",
    component: () => import("../pages/operate/index.vue"),
    meta: {
      title: "処理事業者一覧",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/zipcode",
    component: () => import("../pages/zipcode/index.vue"),
    meta: {
      title: "郵便番号登録CSV",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/contract",
    component: () => import("../pages/disposer/contract/index.vue"),
    meta: {
      title: "CBA契約",
      onlyAllowList: [ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/disposer/step1/input",
    component: () => import("../pages/disposer/step1/input/index.vue"),
    meta: {
      title: "処分事業者登録1",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/step2/input",
    component: () => import("../pages/disposer/step2/input/index.vue"),
    meta: {
      title: "処分事業者登録2",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/step1/edit/:id",
    component: () => import("../pages/disposer/step1/edit/index.vue"),
    meta: {
      title: "処分事業者編集1",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/step2/edit/:id",
    component: () => import("../pages/disposer/step2/edit/index.vue"),
    meta: {
      title: "処分事業者編集2",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/disposer/:id",
    component: () => import("../pages/disposer/detail/index.vue"),
    meta: {
      title: "処分事業者詳細",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/route",
    component: () => import("../pages/route/index.vue"),
    meta: {
      title: "ルート一覧",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/route/edit/:id",
    component: () => import("../pages/route/edit/index.vue"),
    meta: {
      title: "ルート編集",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/route/duplicate",
    component: () => import("../pages/route/duplicate/index.vue"),
    meta: {
      title: "ルート複製",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/route/:id",
    component: () => import("../pages/route/detail/index.vue"),
    meta: {
      title: "ルート詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/entrust",
    component: () => import("../pages/entrust/index.vue"),
    meta: {
      title: "委託契約書一覧",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/entrust/input",
    component: () => import("../pages/entrust/input/index.vue"),
    meta: {
      title: "委託契約書登録",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/entrust/csvupload",
    component: () => import("../pages/entrust/csvupload/index.vue"),
    meta: {
      title: "委託契約書一括CSV取込",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/entrust/confirm",
    component: () => import("../pages/entrust/confirm/index.vue"),
    meta: {
      title: "委託契約書CSV取込状況",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/entrust/edit/:id",
    component: () => import("../pages/entrust/edit/index.vue"),
    meta: {
      title: "委託契約書編集",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/entrust/:id",
    component: () => import("../pages/entrust/detail/index.vue"),
    meta: {
      title: "委託契約書詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL, ROLE.DISPOSER.ALL],
    },
  },
  {
    path: "/licence",
    component: () => import("../pages/licence/index.vue"),
    meta: {
      title: "許可証一覧",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/licence/collect/:id",
    component: () => import("../pages/licence/collect/index.vue"),
    meta: {
      title: "運搬許可証詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/licence/collect/common/:id",
    component: () => import("../pages/licence/collect/common/index.vue"),
    meta: {
      title: "一般廃棄物運搬許可証詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL, ROLE.COLLECTOR.ALL],
    },
  },
  {
    path: "/licence/disposal/:id",
    component: () => import("../pages/licence/disposal/index.vue"),
    meta: {
      title: "処分許可証詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/licence/disposal/common/:id",
    component: () => import("../pages/licence/disposal/common/index.vue"),
    meta: {
      title: "一般廃棄物処分許可証詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/cbaitem",
    component: () => import("../pages/cbaitem/index.vue"),
    meta: {
      title: "品目一覧",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/cbaitem/input",
    component: () => import("../pages/cbaitem/input/index.vue"),
    meta: {
      title: "品目登録",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/cbaitem/edit/:id",
    component: () => import("../pages/cbaitem/edit/index.vue"),
    meta: {
      title: "品目編集",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/wastename/input",
    component: () => import("../pages/wastename/input/index.vue"),
    meta: {
      title: "廃棄物名称登録",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/wastename/edit/:id",
    component: () => import("../pages/wastename/edit/index.vue"),
    meta: {
      title: "廃棄物名称編集",
      onlyAllowList: [ROLE.CBA],
    },
  },
  {
    path: "/zendesk/sso",
    component: () => import("../pages/zendesk/sso.vue"),
    meta: {
      title: "Zendesk SSO",
    },
  },
  {
    path: "/priorconsultation",
    component: () => import("../pages/priorconsultation"),
    meta: {
      title: "事前協議書一覧",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN],
    },
  },
  {
    path: "/priorconsultation/input",
    component: () => import("../pages/priorconsultation/input"),
    meta: {
      title: "事前協議書登録",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN],
    },
  },
  {
    path: "/priorconsultation/:id",
    component: () => import("../pages/priorconsultation/detail/index.vue"),
    meta: {
      title: "事前協議書詳細",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN],
    },
  },
  {
    path: "/priorconsultation/edit/:id",
    component: () => import("../pages/priorconsultation/edit/index.vue"),
    meta: {
      title: "事前協議書編集",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN],
    },
  },
  {
    path: "/priorconsultation/duplicate/:id",
    component: () => import("../pages/priorconsultation/duplicate/index.vue"),
    meta: {
      title: "事前協議書複製",
      onlyAllowList: [ROLE.CBA, ROLE.GENERATOR.ADMIN],
    },
  },
  {
    path: "/static/terms",
    component: () => import("../pages/static/terms/index.vue"),
    meta: {
      title: "利用規約",
      isPublic: true,
    },
  },
  {
    path: "/generate/share/info",
    component: () => import("../pages/shareinfo/index.vue"),
    meta: {
      title: "共有情報一覧",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/generate/share/info/input",
    component: () => import("../pages/shareinfo/input/index.vue"),
    meta: {
      title: "共有情報登録",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/generate/share/info/:id",
    component: () => import("../pages/shareinfo/detail/index.vue"),
    meta: {
      title: "共有情報詳細",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/generate/share/edit/:id",
    component: () => import("../pages/shareinfo/edit/index.vue"),
    meta: {
      title: "共有情報編集",
      onlyAllowList: [ROLE.GENERATOR.ALL],
    },
  },
  {
    path: "/static/privacypolicy",
    component: () => import("../pages/static/privacypolicy/index.vue"),
    meta: {
      title: "プライバシーポリシー",
      isPublic: true,
    },
  },
  {
    path: "/error/:status",
    component: () => import("../pages/error/index.vue"),
    meta: {
      title: "エラーページ",
      isPublic: true,
    },
  },
  {
    path: "*",
    component: () => import("../pages/error/index.vue"),
    meta: {
      title: "お探しのページは見つかりませんでした",
      isPublic: true,
    },
  },
];

// pagelist用
const routesForPageList = [
  {
    path: "/pagelist",
    component: () => import("../pages/pagelist.vue"),
    meta: {
      isPublic: true,
    },
  },
];

routes.push(...routesForPageList);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Auth APIをチェックしてauth storeを更新
const checkAuth = (to, next) => {
  return axios
    .post(API_URL.AUTH.AUTH, {pathinfo: to.path})
    .then((res) => {
      if (res.status === 204) {
        store.commit("auth/set", true);
      }
    })
    .catch((err) => {
      console.log(err);
      store.commit("auth/set", false);

      if (err.response && err.response.data) {
        const errorCode = err.response.data.code;
        if (errorCode === "authError") {
          next({
            path: "/login",
            query: { redirect: encodeURIComponent(to.fullPath) },
          });
        } else if (errorCode === "loginExpired") {
          const memberId = err.response.data.memberId,
            onetimeToken = err.response.data.onetimeToken;
          next({
            path: `/reminder/${memberId}/input`,
            query: { token: onetimeToken },
          });
        }
      } else {
        next({
          path: "/login",
          query: { redirect: encodeURIComponent(to.fullPath) },
        });
      }
    });
};

const getProfileApi = () => {
  return axios
    .get(API_URL.PROFILE.PROFILE)
    .then(async (res) => {
      if (res.status === 200) {
        store.commit("userInfo/set", res.data);

        if (res.data.userTypeId >= 30 && res.data.userTypeId < 40) {
          await axios.get(API_URL.COLLECTOR.CHECK_PROXY).then((res) => {
            store.commit("userInfo/setCollectProxy", res.data.isCollectProxy);
          }).catch((err) => {
            console.log(err);
          });
        }
        if (res.data.userTypeId >= 40) {
          await axios.get(API_URL.DISPOSER.CHECK_PROXY).then((res) => {
            store.commit("userInfo/setDisposalProxy", res.data.isDisposalProxy);
          }).catch((err) => {
            console.log(err);
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const getMenuApi = () => {
  return axios
    .get(API_URL.MENU.MENU)
    .then((res) => {
      if (res.status === 200) {
        store.commit("menu/set", res.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

router.beforeEach((to, from, next) => {
  if (to.meta.isPublic) {
    if (to.path === "/login" && process.env.VUE_APP_USE_AUTH == "true") {
      let path;
      if (to.query.redirect !== undefined) {
        path = to.query.redirect;
      } else {
        path = encodeURIComponent("/");
      }
      window.location =
        process.env.VUE_APP_AUTH_URL +
        "?redirect_uri=" +
        encodeURIComponent( window.location.protocol + "//" + window.location.host) + path;
    }
    // ログイン状態問わずに認証チェックをスキップ
    next();
  } else {
    checkAuth(to, next)
      .then(() => {
        if (store.getters["auth/get"]) {
          // プロフィールAPI，メニューAPIを画面遷移毎に実行
          getMenuApi().then(() => {
            getProfileApi().then(() => {
              // 許可リストが無い場合、アクセス権限チェックをスルー
              if (!to.meta.onlyAllowList) {
                next();
              } else {
                if(to.path === "/manifest/csvupload/") {
                  if (store.getters["userInfo/isDisposer"]) {
                    to.meta.title = "マニフェスト新規登録 - CSV取込"
                  } else {
                    to.meta.title = "マニフェスト予約登録 - CSV取込"
                  }
                }
                // アクセス権限があるかチェック
                const isAllowed = to.meta.onlyAllowList
                  .map((allow) => {
                    switch (allow) {
                      case ROLE.CBA:
                        return store.getters["userInfo/isCba"];
                      case ROLE.GENERATOR.ALL:
                        return store.getters["userInfo/isGenerator"];
                      case ROLE.GENERATOR.ADMIN:
                        return (
                          store.getters["userInfo/isGenerator"] &&
                          store.getters["userInfo/isAdmin"]
                        );
                      case ROLE.GENERATOR.HIGH_CLASS:
                        return store.getters["userInfo/isHighClass"];
                      case ROLE.GENERATOR.CONTRACTOR:
                        return store.getters["userInfo/isGenerateContractor"];
                      case ROLE.COLLECTOR.ALL:
                        return store.getters["userInfo/isCollector"];
                      case ROLE.COLLECTOR.ADMIN:
                        return (
                          store.getters["userInfo/isCollector"] &&
                          store.getters["userInfo/isAdmin"]
                        );
                      case ROLE.COLLECTOR.PROXY:
                        return store.getters["userInfo/isCollectProxy"];
                      case ROLE.DISPOSER.ALL:
                        return (
                          store.getters["userInfo/isDisposer"]
                        );
                      case ROLE.DISPOSER.ADMIN:
                        return (
                          store.getters["userInfo/isDisposer"] &&
                          store.getters["userInfo/isAdmin"]
                        );
                      case ROLE.DISPOSER.PROXY:
                        return store.getters["userInfo/isDisposalProxy"];
                      default:
                        return false;
                    }
                  })
                  .includes(true);

                if (isAllowed) {
                  next();
                } else {
                  next({ path: "/error/403" });
                }
              }
            });
          });
        }
      })
      .catch((err) => console.error(err));
  }
});

export default router;
